import * as e from "../enums";

export const LOGO = "/img/logo.webp";

export const ICONS: Record<any, string> = {
	[e.Modules.INTERVALS]: "/img/1.webp",
	[e.Modules.SCALES]: "/img/2.webp",
	[e.Modules.MELODIC_DICTATION]: "/img/3.webp",
	[e.Modules.CHORDS]: "/img/4.webp",
	[e.Modules.INVERSIONS]: "/img/5.webp",
	[e.Modules.PROGRESSIONS]: "/img/6.webp",
	[e.Icons.EXERCISE]: "/img/headphones.webp",
	[e.Icons.STATISTICS]: "/img/stats.webp",
	[e.Icons.SETTINGS]: "/img/settings.webp",
	[e.Icons.EXAMS]: "/img/exams.webp",
	[e.Icons.EXAM]: "/img/exam.webp",
	[e.Icons.STREAK]: "/img/streak.webp",
	[e.Icons.STREAK_ZERO]: "/img/streakZero.webp",
	[e.Icons.TIMER]: "/img/timer.webp",
	[e.Icons.GOLD]: "/img/gold-medal.webp",
	[e.Icons.SILVER]: "/img/silver-medal.webp",
	[e.Icons.BRONZE]: "/img/bronze-medal.webp",
	[e.Icons.KNOWLEDGE]: "/img/knowledge.webp",
	[e.Icons.DIFFICULTY]: "/img/difficulty.webp",
	[e.Icons.LEARNING_CURVE]: "/img/learningCurve.webp",
	[e.Icons.SCORES]: "/img/scores.webp",
	[e.Icons.CUP]: "/img/cup.webp",
	[e.Icons.CHECK_CORRECT]: "/img/checkCorrect.webp",
	[e.Icons.TRACK_PROGRESS]: "/img/track.webp",
	[e.Icons.ERROR_404]: "/img/error404.webp",
	[e.Icons.APP_STORE]: "/img/appStore.webp",
	[e.Icons.PLAY_STORE]: "/img/playStore.webp",
};

export const SCREENSHOTS: Record<any, string> = {
	[e.ScreenShots.HOME]: "/img/screenshotHome.webp",
	[e.ScreenShots.GAMEPLAY]: "/img/screenshotGameplay.webp",
	[e.ScreenShots.STATS]: "/img/screenshotStats.webp",
	[e.ScreenShots.STUDENT_STATS_1]: "/img/screenshotStudentStats1.webp",
	[e.ScreenShots.STUDENT_STATS_2]: "/img/screenshotStudentStats2.webp",
};

export const FLAGS: Record<any, string> = {
	// [e.Languages.GREEK]: "/img/flags/greece.webp",
	[e.Languages.CATALAN]: "/img/flags/catalan.webp",
	[e.Languages.DANISH]: "/img/flags/denmark.webp",
	[e.Languages.DUTCH]: "/img/flags/netherlands.webp",
	[e.Languages.ENGLISH_UK]: "/img/flags/uk.webp",
	[e.Languages.ENGLISH_US]: "/img/flags/usa.webp",
	[e.Languages.FINNISH]: "/img/flags/finland.webp",
	[e.Languages.FRENCH]: "/img/flags/france.webp",
	[e.Languages.GERMAN]: "/img/flags/germany.webp",
	[e.Languages.HINDI]: "/img/flags/india.webp",
	[e.Languages.ITALIAN]: "/img/flags/italy.webp",
	[e.Languages.NORWEGIAN]: "/img/flags/norway.webp",
	[e.Languages.PORTUGUESE]: "/img/flags/portugal.webp",
	[e.Languages.SPANISH]: "/img/flags/spain.webp",
	[e.Languages.SWEDISH]: "/img/flags/sweden.webp",
};
