import React from "react";
import { useTranslation } from "react-i18next";

export default function AboutUs(): JSX.Element {
	const { t } = useTranslation("AboutUs");

	return (
		<main>
			<h1>{t("aboutUs.title")}</h1>
			<div className="card">
				<h2>{t("aboutUs.comingSoon")}</h2>
			</div>
		</main>
	);
}
