import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import * as e from "../data/enums";

const localStorageLanguage = localStorage.getItem("language") as e.Languages;
const browserLanguage = navigator.language.split("-")[0] as e.Languages;

const userLanguage = Object.values(e.Languages).includes(localStorageLanguage)
	? localStorageLanguage
	: Object.values(e.Languages).includes(browserLanguage)
	? browserLanguage
	: e.Languages.ENGLISH_US;

i18n.use(HttpBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: e.Languages.ENGLISH_US,
		lng: userLanguage,
		debug: false,
		ns: ["Home", "Contact", "AboutUs", "ErrorPage", "Schools", "FAQ", "Footer", "Header", "Pricing"],
		defaultNS: "Home",
		interpolation: {
			escapeValue: false,
		},
		backend: {
			loadPath: "/locales/{{lng}}/{{ns}}.json",
		},
	});

export default i18n;
