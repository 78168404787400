import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import * as image from "../data/constants/images";
import * as e from "../data/enums";

export default function Schools(): JSX.Element {
	const { t, i18n } = useTranslation("Schools");
	const [animateFeature1, setAnimateFeature1] = useState(false);
	const [animateFeature2, setAnimateFeature2] = useState(false);
	const [animateFeature3, setAnimateFeature3] = useState(false);
	const [animationClass, setAnimationClass] = useState("");

	useEffect(() => {
		const animationsPlayedSchools = localStorage.getItem("animationsPlayedSchools");
		if (animationsPlayedSchools) return;
		setAnimationClass("hide");
		setAnimateFeature1(true);
		setAnimateFeature2(true);
		setAnimateFeature3(true);

		localStorage.setItem("animationsPlayedSchools", "true");
	}, []);

	const animationFeature1 = animateFeature1 ? "animate-fade-in" : animationClass;
	const animationFeature2 = animateFeature2 ? "animate-fade-in" : animationClass;
	const animationFeature3 = animateFeature3 ? "animate-fade-in" : animationClass;

	return (
		<main>
			<Helmet>
				<title>{t("schools.pageTitle")}</title>
				<meta name="description" content={t("schools.pageDescription")} />
				<meta http-equiv="Content-Language" content={i18n.language} />
			</Helmet>

			<section className={`features`}>
				<div className={`feature ${animationFeature1}`}>
					<div className="feature-card">
						<h2>{t("schools.trackProgress.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.TRACK_PROGRESS]} alt={t("schools.trackProgress.alt")} />
							{t("schools.trackProgress.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.DIFFICULTY]}
								alt={t("schools.trackProgress.difficulty.alt")}
							/>
							{t("schools.trackProgress.difficulty.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.SETTINGS]} alt={t("schools.trackProgress.settings.alt")} />
							{t("schools.trackProgress.settings.description")}
						</div>
					</div>
				</div>

				<img
					src={image.SCREENSHOTS[e.ScreenShots.STUDENT_STATS_1]}
					alt=""
					className={`feature ${animationFeature1}`}
				/>

				<div className={`feature ${animationFeature2}`}>
					<div className="feature-card">
						<h2>{t("schools.setTargets.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.GOLD]} alt={t("schools.setTargets.goals.alt")} />
							{t("schools.setTargets.goals.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.KNOWLEDGE]} alt={t("schools.setTargets.knowledge.alt")} />
							{t("schools.setTargets.knowledge.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.LEARNING_CURVE]} alt={t("schools.setTargets.progress.alt")} />
							{t("schools.setTargets.progress.description")}
						</div>
					</div>
					{/* <img src={image.SCREENSHOTS[e.ScreenShots.CHOOSE_LEVEL]} alt="" /> */}
				</div>

				<img
					src={image.SCREENSHOTS[e.ScreenShots.STUDENT_STATS_2]}
					alt=""
					className={`feature ${animationFeature1}`}
				/>

				<div className={`feature ${animationFeature3}`}>
					{/* <img src={image.SCREENSHOTS[e.ScreenShots.GAMEPLAY]} alt="" /> */}
					<div className="feature-card">
						<h2>{t("schools.tailoredTraining.title")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.EXAM]} alt={t("schools.tailoredTraining.exam.alt")} />
							{t("schools.tailoredTraining.exam.description")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.CUP]} alt={t("schools.tailoredTraining.auditions.alt")} />
							{t("schools.tailoredTraining.auditions.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.STATISTICS]}
								alt={t("schools.tailoredTraining.statistics.alt")}
							/>
							{t("schools.tailoredTraining.statistics.description")}
						</div>
						<div className="feature-card-element">
							<img
								src={image.ICONS[e.Icons.CHECK_CORRECT]}
								alt={t("schools.tailoredTraining.results.alt")}
							/>
							{t("schools.tailoredTraining.results.description")}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
