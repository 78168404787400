import React from "react";

export default function CookiePolicy(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Cookie Policy for Auris Ear Training</h1>
					<CookiePolicyContent />
				</div>
			</section>
		</main>
	);
}

export function CookiePolicyContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 17 March 2024
				</p>
			</div>

			<article>
				<h2>1. What Are Cookies?</h2>
				<p>
					Cookies are small pieces of data stored on your device (computer, smartphone, etc.) when you visit
					our website. They allow us to recognize you on subsequent visits, save your preferences, and deliver
					a tailored experience to you.
				</p>
			</article>

			<article>
				<h2>2. How We Use Cookies</h2>
				<p>At Auris Ear Training, we use cookies for several purposes:</p>
				<ul>
					<li>
						Authentication and Security: To keep you logged in, recognize you on our platform, and ensure
						your data is safe and not accessed by any third parties.
					</li>
					<li>
						Preferences: To remember settings and preferences you've selected, like language or difficulty
						level, to offer a personalized experience.
					</li>
					<li>
						Performance and Analytics: We use Google Analytics to understand how our users engage with our
						platform. This helps us improve our tool and offer better, more effective ear training
						exercises.
					</li>
					<li>
						Payment Processing: We utilize Stripe for payment processing, and Stripe uses cookies to ensure
						transactions are safe, secure, and tailored to your preferences.
					</li>
					<li>
						Advertising: We may use Google AdSense to display relevant advertisements to our users. AdSense uses
						cookies to tailor ads based on your online activity and preferences.
					</li>
					<li>
						Backend Services: We use Google Firebase to offer certain backend services, including
						authentication and database storage. Firebase might use cookies to ensure a smooth and secure
						user experience.
					</li>
				</ul>
			</article>

			<article>
				<h2>3. Types of Cookies We Use</h2>
				<ul>
					<li>Session Cookies: Temporary cookies that are erased when you close your browser.</li>
					<li>Persistent Cookies: Cookies that stay on your device until you delete them or they expire.</li>
					<li>Required Cookies: Essential for basic functions and navigation on our platform.</li>
					<li>
						Analytics and Performance Cookies: These help us to monitor platform performance and user
						interaction. We use tools like Google Analytics to understand how users engage with our
						platform.
					</li>
					<li>
						Advertising Cookies: Used to deliver relevant ads to you, including those served by Google
						AdSense. You can control these through your browser settings.
					</li>
					<li>Security Cookies: Employed to protect your data and prevent security risks.</li>
					<li>
						Site Management Cookies: Necessary to maintain your session and preferences throughout your
						visit.
					</li>
					<li>
						Third-party Cookies: In addition to our own cookies, we work with reputable companies like
						Google for analytics and Stripe for payment processing to enhance your experience. These
						partners may set cookies to provide certain features on our platform.
					</li>
					<li>
						Use of Google AdSense Cookies: Our website may use Google AdSense, a service for including
						advertisements from Google Inc. The information generated by
						the cookie about your use of the website (including your IP address) will be transmitted to and
						stored by Google. For more information on how Google uses data when you use our site, please visit:
						<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid"> [Google's Advertising Privacy & Terms]</a>.
					</li>
				</ul>
			</article>

			<article>
				<h2>4. Managing and Disabling Cookies</h2>
				<p>
					You can control the use of cookies at the individual browser level. Disabling cookies may limit your
					use of certain features or functions on our platform. For more information on how to manage cookies,
					please see the following links:
				</p>
				<ul>
					<li>
						<a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
							Apple Safari
						</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647">Google Chrome</a>
					</li>
					<li>
						<a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies">
							Microsoft Edge
						</a>
					</li>
					<li>
						<a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
							Microsoft Internet Explorer
						</a>
					</li>
					<li>
						<a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
							Mozilla Firefox
						</a>
					</li>
					<li>
						<a href="https://help.opera.com/en/latest/web-preferences/#cookies">Opera</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid">
							Android (Chrome)
						</a>
					</li>
					<li>
						<a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS">
							iPhone or iPad (Chrome)
						</a>
					</li>
					<li>
						<a href="https://support.apple.com/en-us/HT201265">iPhone or iPad (Safari)</a>
					</li>
				</ul>
			</article>

			<article>
				<h2>5. Changes to This Cookie Policy</h2>
				<p>
					We may update this policy from time to time to reflect changes in our practices or for other
					operational, legal, or regulatory reasons. Please revisit this policy regularly to stay informed.
				</p>
			</article>

			<article>
				<h2>6. Contact Us</h2>
				<p>
					If you have any questions about our use of cookies or other technologies, please email us at{" "}
					<a href="mailto:info@auriseartraining.com">info@auriseartraining.com</a>.
				</p>
				<p>
					You can also refer to our <a href="/PrivacyPolicy">Privacy Policy</a> for more information about how
					we collect and use your data.
				</p>
			</article>
		</div>
	);
}
