import React from "react";
// import useAdSenseScript from "../hooks/useAdSenseScript";

export default function AdBanner() {
	// useAdSenseScript();

	return <></>;
	// return (
	// 	<div className="adsBanner">
	// 		<ins
	// 			className="adsbygoogle"
	// 			style={{ display: "block" }}
	// 			data-ad-client="ca-pub-5379492270779551"
	// 			data-ad-slot="4488820011"
	// 			data-ad-format="auto"
	// 			data-full-width-responsive="true"
	// 		></ins>
	// 	</div>
	// );
}
