import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Testimonials(): JSX.Element {
    const { t } = useTranslation('Contact');

    return (
        <main>
            <div className="card">
                <div>
                    <h1>{t('contact.title')}</h1>
                    <span>info@auriseartraining.com</span>
                </div>
            </div>
        </main>
    );
}
