import React from "react";

export default function Testimonials(): JSX.Element {
	return (
		<main>
			<h1>Hear It from Our Users</h1>
			"As a music student, this app has transformed my listening skills. The insights section is a game-changer!"
			– Sophia L.
			<br />
			"Incorporating this into our school's curriculum was the best decision. The exam creation feature is
			unmatched!" – Mr. Harrison, Music Educator
			<br />
		</main>
	);
}
