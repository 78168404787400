import React from "react";

export default function Support(): JSX.Element {
	return (
		<main>
			{/* <h1>Support</h1> */}
			<div className="card">
				<h2>Coming soon...</h2>
			</div>
		</main>
	);
}
