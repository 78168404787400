import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import * as image from "../data/constants/images";
import * as e from "../data/enums";
import * as c from "../data/constants/constants";
import { LOGO } from "../data/constants/images";

export default function Home(): JSX.Element {
	const { t, i18n } = useTranslation("Home");
	const [animateHero, setAnimateHero] = useState(false);
	const [animateButtonWatchDemo, setAnimateButtonWatchDemo] = useState(false);
	const [animateButtonTryForFree, setAnimateButtonTryForFree] = useState(false);
	const [animateFeatures, setAnimateFeatures] = useState(false);
	const [animationClass, setAnimationClass] = useState("");

	useEffect(() => {
		const animationsPlayedSchools = localStorage.getItem("animationsPlayedHome");
		if (animationsPlayedSchools) return;
		setAnimationClass("hide");
		setAnimateHero(true);
		setAnimateButtonWatchDemo(true);
		setAnimateButtonTryForFree(true);
		setAnimateFeatures(true);

		localStorage.setItem("animationsPlayedHome", "true");
	}, []);

	const animationClass1 = animateHero ? "animate-fade-in" : animationClass;
	const animationClass2 = animateButtonWatchDemo ? "animate-fade-in" : animationClass;
	const animationClass3 = animateButtonTryForFree ? "animate-fade-in" : animationClass;
	const animationClass4 = animateFeatures ? "animate-fade-in" : animationClass;

	return (
		<main className="home">
			<Helmet>
				<title>{t("pageTitle")}</title>
				<meta name="description" content={t("pageDescription")} />
				<meta name="keywords" content={t("keywords")} />
				<meta http-equiv="Content-Language" content={i18n.language} />
			</Helmet>

			<section className={`hero ${animationClass1}`}>
				<img id="logo" alt="logo Auris Ear Training" src={LOGO} />
				<div className="heroTextAndLogo">
					<h1>Auris Ear Training</h1>
					<p className="long">{t("heroTextLong")}</p>
					<p className="short">{t("heroTextShort")}</p>
				</div>
			</section>

			<div className="button-container">
				<Link to={c.loginStudentPortal} className={`button button-secondary ${animationClass2}`}>
					{t("login")}
				</Link>
				<Link to={c.studentPortal} className={`button signUp ${animationClass3}`}>
					{t("tryWithoutLogin")}
				</Link>

				<button
					className={`button-stores ${animationClass3}`}
					onClick={() => (window.location.href = c.linkToPlayStore)}
					aria-label={t("downloadPlayStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.PLAY_STORE]} alt="Play Store" />
				</button>
				<button
					className={`button-stores ${animationClass3}`}
					onClick={() => (window.location.href = c.linkToAppStore)}
					aria-label={t("downloadAppStore")}
				>
					<img className="storeImg" src={image.ICONS[e.Icons.APP_STORE]} alt="App Store" />
				</button>
			</div>

			<section className={`features ${animationClass4}`}>
				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.HOME]}
						alt="screenshot of student portal home page"
					/>
					<div className="feature-card">
						<h2>{t("features.trainYourEars")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INTERVALS]} alt="" />
							{t("modules.intervals")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.SCALES]} alt="" />
							{t("modules.scales")}{" "}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.MELODIC_DICTATION]} alt="" />
							{t("modules.melodicDictation")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.CHORDS]} alt="" />
							{t("modules.chords")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.INVERSIONS]} alt="" />
							{t("modules.inversions")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Modules.PROGRESSIONS]} alt="" />
							{t("modules.progressions")}
						</div>
					</div>
				</div>

				<div className="feature">
					<div className="feature-card">
						<h2>{t("features.funExercises")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.DIFFICULTY]} alt="" />
							{t("features.beginnersAndAdvanced")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.LEARNING_CURVE]} alt="" />
							{t("features.gradualDifficulty")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.KNOWLEDGE]} alt="" />
							{t("features.noPriorKnowledge")}
						</div>
					</div>
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.GAMEPLAY]}
						alt="screenshot of gameplay"
					/>
				</div>

				<div className="feature">
					<img
						className="screenshot"
						src={image.SCREENSHOTS[e.ScreenShots.STATS]}
						alt="screenshot of stats page"
					/>
					<div className="feature-card">
						<h2>{t("features.trackProgress")}</h2>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.STREAK]} alt="" />
							{t("features.practiceDaily")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.CUP]} alt="" />
							{t("features.beatHighScore")}
						</div>
						<div className="feature-card-element">
							<img src={image.ICONS[e.Icons.STATISTICS]} alt="" />
							{t("features.detailedStats")}
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}
