import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LOGO } from "../data/constants/images";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as e from "../data/enums";
import * as img from "../data/constants/images";

export default function Header(): JSX.Element {
	const isMobile = useDeviceIsMobile();
	const [changeLanguage, setChangeLanguage] = React.useState(false);
	const chooseLanguageRef = useRef<HTMLDivElement>(null);

	// Handling click outside to reset changeLanguage
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (chooseLanguageRef.current && !chooseLanguageRef.current.contains(event.target as Node))
				setChangeLanguage(false);
		};

		document.body.addEventListener("click", handleClickOutside);
		return () => document.body.removeEventListener("click", handleClickOutside);
	}, []);

	return (
		<header>
			<ChooseLanguage
				ref={chooseLanguageRef}
				changeLanguage={changeLanguage}
				setChangeLanguage={setChangeLanguage}
			/>
			{isMobile ? <MenuMobile /> : <MenuWeb />}
		</header>
	);
}

function MenuWeb() {
	const { t } = useTranslation("Header");
	const navigate = useNavigate();

	return (
		<>
			<img id="logo" alt={t("header.logoAlt")} src={LOGO} onClick={() => navigate("/")} />
			<nav className="navigation">
				<Link to="/Home">{t("header.home")}</Link>
				<Link to="/Schools">{t("header.forSchools")}</Link>
				<Link to="/FAQ">{t("header.faqs")}</Link>
				<Link to="/Pricing">{t("header.pricing")}</Link>
				{/* <Link to="/Support">Support</Link> */}
			</nav>
			<nav className="loginSignUp">
				<Link to="https://student.auriseartraining.com/login" className="login">
					{t("header.login")}
				</Link>
				/
				<Link to="https://student.auriseartraining.com/signup" className="signup">
					{t("header.signUp")}
				</Link>
				/
				<Link to="https://teacher.auriseartraining.com" className="signup">
					{t("header.teacher")}
				</Link>
			</nav>
		</>
	);
}

function MenuMobile() {
	const { t } = useTranslation("Header");
	const [menuOpen, setMenuOpen] = React.useState(false);
	const navigate = useNavigate();
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	const closeMenu = () => {
		setMenuOpen(false);
	};

	return (
		<>
			<img id="logo" alt={t("header.logoAlt")} src={LOGO} onClick={() => navigate("/")} />
			{/* hamburgermenu */}
			<div className="hamburger-menu" onClick={toggleMenu}>
				<div className="line" />
				<div className="line" />
				<div className="line" />
			</div>
			<div className="menu-mobile" style={{ display: menuOpen ? "block" : "none" }}>
				<nav className="navigation-mobile">
					<Link onClick={closeMenu} to="/Home">
						{t("header.home")}
					</Link>
					<Link onClick={closeMenu} to="/Schools">
						{t("header.forSchools")}
					</Link>
					<Link onClick={closeMenu} to="/FAQ">
						{t("header.faqs")}
					</Link>
					<Link onClick={closeMenu} to="/Pricing">
						{t("header.pricing")}
					</Link>
					{/* <Link onClick={closeMenu} to="/Support">
						Support
					</Link> */}
					<Link onClick={closeMenu} to="https://student.auriseartraining.com/login" className="login">
						{t("header.login")}
					</Link>
					<Link onClick={closeMenu} to="https://student.auriseartraining.com/signup" className="signup">
						{t("header.signUp")}
					</Link>
					<Link onClick={closeMenu} to="https://teacher.auriseartraining.com" className="signup">
						{t("header.teacher")}
					</Link>
				</nav>
			</div>
		</>
	);
}

const ChooseLanguage = React.forwardRef<
	HTMLDivElement,
	{
		changeLanguage: boolean;
		setChangeLanguage: React.Dispatch<React.SetStateAction<boolean>>;
	}
>(({ changeLanguage, setChangeLanguage }, ref) => {
	const currentLanguage = i18next.language as e.Languages;
	const [currentLanguageFlag, setCurrentLanguageFlag] = React.useState(img.FLAGS[currentLanguage]);

	const handleSelectLanguage = (lang: string) => {
		setChangeLanguage(false);
		if (lang === currentLanguage) return;
		i18next.changeLanguage(lang);
		localStorage.setItem("language", lang);
		setCurrentLanguageFlag(img.FLAGS[lang]);
	};

	return (
		<div className="language" ref={ref}>
			{changeLanguage && (
				<div className="flagPopup">
					<img
						className={currentLanguage === e.Languages.CATALAN ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.CATALAN]}
						onClick={() => handleSelectLanguage(e.Languages.CATALAN)}
						alt="Catalan"
						title="Catalan"
					/>
					<img
						className={currentLanguage === e.Languages.DANISH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.DANISH]}
						onClick={() => handleSelectLanguage(e.Languages.DANISH)}
						alt="Danish"
						title="Danish"
					/>
					<img
						className={currentLanguage === e.Languages.DUTCH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.DUTCH]}
						onClick={() => handleSelectLanguage(e.Languages.DUTCH)}
						alt="Dutch"
						title="Dutch"
					/>
					<img
						className={currentLanguage === e.Languages.ENGLISH_UK ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.ENGLISH_UK]}
						onClick={() => handleSelectLanguage(e.Languages.ENGLISH_UK)}
						alt="English UK"
						title="English UK"
					/>
					<img
						className={currentLanguage === e.Languages.ENGLISH_US ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.ENGLISH_US]}
						onClick={() => handleSelectLanguage(e.Languages.ENGLISH_US)}
						alt="English US"
						title="English US"
					/>
					<img
						className={currentLanguage === e.Languages.FINNISH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.FINNISH]}
						onClick={() => handleSelectLanguage(e.Languages.FINNISH)}
						alt="Finnish"
						title="Finnish"
					/>
					<img
						className={currentLanguage === e.Languages.FRENCH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.FRENCH]}
						onClick={() => handleSelectLanguage(e.Languages.FRENCH)}
						alt="French"
						title="French"
					/>
					<img
						className={currentLanguage === e.Languages.HINDI ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.HINDI]}
						onClick={() => handleSelectLanguage(e.Languages.HINDI)}
						alt="Hindi"
						title="Hindi"
					/>
					<img
						className={currentLanguage === e.Languages.GERMAN ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.GERMAN]}
						onClick={() => handleSelectLanguage(e.Languages.GERMAN)}
						alt="German"
						title="German"
					/>
					<img
						className={currentLanguage === e.Languages.ITALIAN ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.ITALIAN]}
						onClick={() => handleSelectLanguage(e.Languages.ITALIAN)}
						alt="Italian"
						title="Italian"
					/>
					<img
						className={currentLanguage === e.Languages.NORWEGIAN ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.NORWEGIAN]}
						onClick={() => handleSelectLanguage(e.Languages.NORWEGIAN)}
						alt="Norwegian"
						title="Norwegian"
					/>
					<img
						className={currentLanguage === e.Languages.PORTUGUESE ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.PORTUGUESE]}
						onClick={() => handleSelectLanguage(e.Languages.PORTUGUESE)}
						alt="Portuguese"
						title="Portuguese"
					/>
					<img
						className={currentLanguage === e.Languages.SPANISH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.SPANISH]}
						onClick={() => handleSelectLanguage(e.Languages.SPANISH)}
						alt="Spanish"
						title="Spanish"
					/>
					<img
						className={currentLanguage === e.Languages.SWEDISH ? "flag flagActive" : "flag"}
						src={img.FLAGS[e.Languages.SWEDISH]}
						onClick={() => handleSelectLanguage(e.Languages.SWEDISH)}
						alt="Swedish"
						title="Swedish"
					/>
				</div>
			)}
			<img
				className="flag"
				src={currentLanguageFlag}
				onClick={() => setChangeLanguage(true)}
				alt="Change Language"
			/>
		</div>
	);
});
