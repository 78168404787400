import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useDeviceIsMobile from "../hooks/useDeviceIsMobile";

export default function Footer(): JSX.Element {
	const { t } = useTranslation("Footer");

	const isMobile = useDeviceIsMobile();
	const currentYear = new Date().getFullYear();
	return (
		<footer>
			<nav>
				<Link to="/Legal">{t("footer.legal")}</Link>
				{/* <Link to="/AboutUs">About Us</Link> */}
				<Link to="/Contact">{t("footer.contact")}</Link>
			</nav>
			<div className="rights">
				&copy; {currentYear} Auris Ear Training. {isMobile && <br />}
				{t("footer.patent")}
			</div>
		</footer>
	);
}
